<template>
  <section class="section section--404">
    <div class="container">
      <div class="login__wrapper">
        <div class="login__box">
          <form class="login__form" @submit.prevent="login">
            <div class="login__title">Log In</div>
            <div class="login__main">
              <label
                class="login__field field__label"
                :class="{
                  'field__label--filled': $v.email.required && $v.email.email,
                  'field__label--invalid':
                    ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email) || incorectEmail,
                  'animate__shakeX animate__animated': incorectEmail
                }"
              >
                <div class="field__title">E-mail address</div>
                <input v-model="email" class="field__input" />
              </label>
              <label
                class="login__field field__label"
                :class="{
                  'field__label--filled': $v.password.required && $v.password.minLength,
                  'field__label--invalid':
                    ($v.password.$dirty && !$v.password.required) ||
                    ($v.password.$dirty && !$v.password.minLength) ||
                    wrongPassword,
                  'animate__shakeX animate__animated': wrongPassword
                }"
              >
                <div class="field__title">Password</div>
                <input v-model="password" type="password" class="field__input" />
              </label>
              <div class="btn-wrap">
                <button class="button button--blue login__button btn btn--bgr" type="submit">Log in</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {required, email, minLength} from 'vuelidate/lib/validators'
import {mapActions, mapState} from 'vuex'

export default {
  name: 'Login',
  data: () => ({
    email: '',
    password: '',
    incorectEmail: false,
    wrongPassword: false
  }),
  computed: {
    ...mapState({
      user: s => s.auth.user
    })
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn'
    }),
    async login() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const formData = {
        email: this.email,
        password: this.password
      }
      await this.signIn(formData)
      if (this.user.isAdmin) this.$router.push('/admin')
    }
  },
  validations: {
    email: {required, email},
    password: {required, minLength: minLength(8)}
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
